import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
	{
	path: '/',
	name: 'home',
	component: () => import('@/views/home/index.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '首页',
		// icon: 'tree-table'
	}
},
{
	path: '/news',
	name: 'news',
	component: () => import('@/views/news'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '新闻资讯',
		// icon: 'tree-table'
	}
},
{
	path: '/newsD',
	name: 'newsD',
	component: () => import('@/views/news/newsD.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '服务支持',
		// icon: 'tree-table'
	}
},
{
	path: '/aboutUs',
	name: 'aboutUs',
	component: () => import('../views/aboutUs/index.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '关于我们',
		// icon: 'tree-table'
	}
},
{
	path: '/connectionUs',
	name: 'connectionUs',
	component: () => import('@/views/connectionUs'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '联系我们',
		// icon: 'tree-table'
	}
},
{
	path: '/xiaoduyeD/:id?',
	name: 'xiaoduyeD',
	component: () => import('@/views/goodsDetail/xiaoduyeD.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '产品',
		// icon: 'tree-table'
	}
},
{
	path: '/mhoe',
	name: 'mhoe',
	component: () => import('@/views/home/mhoe.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '首页',
		// icon: 'tree-table'
	}
},
{
	path: '/mlist',
	name: 'mlist',
	component: () => import('../views/aboutUs/Mlist.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '我们',
		// icon: 'tree-table'
	}
},
{
	path: '/mcoop',
	name: 'mcoop',
	component: () => import('@/views/goodsDetail/Mcoop.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '招人',
		// icon: 'tree-table'
	}
},
{
	path: '/mnews',
	name: 'mnews',
	component: () => import('@/views/news/Mnews.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '服务支持',
		// icon: 'tree-table'
	}
},
{
	path: '/mnew88',
	name: 'mnew',
	component: () => import('@/views/news/Mnew88.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '服务支持',
		// icon: 'tree-table'
	}
},
{
	path: '/mproduct',
	name: 'mproduct',
	component: () => import('@/views/goodsDetail/Mproduct.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '乘用车',
		// icon: 'tree-table'
	}
},
{
	path: '/m88',
	name: 'm88',
	component: () => import('@/views/news/M88.vue'),
	meta: {
		//因为左侧菜单显示读取的就是title
		title: '服务支持',
		// icon: 'tree-table'
	}
},
]

const router = new VueRouter({
		scrollBehavior: () => ({ y: 0 }),
		routes
})
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err)
// }
export default router
