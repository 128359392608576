import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
// import router from './router'
import store from './store'
import '@/styles/index.scss'
import 'amfe-flexible'
import Vant from 'vant';
import router from './router/index'
// import geoJson from '@/utils/china.json'
// import * as echarts from 'echarts';
// echarts.registerMap('china', geoJson);
import { Icon } from 'vant';
import 'vant/lib/index.css';
import VueRouter from 'vue-router'
import { Popup } from 'vant';
import { Lazyload } from 'vant';
import { Pagination } from 'vant';
// 解决同一页面产生导航冗余，vue-router报错问题

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
Vue.use(ElementUI).use(Vant).use(Icon).use(Popup).use(Lazyload).use(Pagination);
// Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
