<template>
  <div id="app">
    <!-- 头部导航栏 -->
    <!-- <headerBar></headerBar> -->
    <router-view :key="key" />
    <!-- <footerBar></footerBar> -->
  </div>
</template>

<!-- 特殊节日颜色 -->
<style>
	/* html {
	filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
	-webkit-filter: grayscale(100%);} */
</style>
<script>
// import headerBar from '@/components/headerBar'
// import footerBar from '@/components/footerBar'
export default {
  // components: { headerBar, footerBar },
  data () {
    return {};
  },
  computed: {
    key () {
      // 只要保证 key 唯一性就可以了，保证不同页面的 key 不相同
      // console.log(this.$route.fullPath);
      return this.$route.fullPath
    }
  },
  mounted(){
    if (this._isMobile()) {
      // 手机端
      this.$router.replace('/mhoe');
    } else {
      // pc端
      this.$router.replace('/');
    }
},

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
     /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
  }
}
</script>
<style lang="scss">
</style>
