import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		shownavBox: false,
		showNav: null,
	},
	getters: {},
	mutations: {
		editShownavBox (state, data) {
			state.shownavBox = data
		},
		editShowNav (state, data) {
			// console.log(999,data)
			state.showNav = data
		}
	},
	actions: {},
	modules: {}
})
